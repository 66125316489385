import PageError from '@/views/PageError'

const Home = () => import('@/views/Home.vue')
const ProgramContent = () => import(
  '@/views/SchoolManagement/ProgramContent.vue'
)
const PromotionalMaterial = () => import(
  '@/views/SchoolManagement/PromotionalMaterial.vue'
)
const AditionalContent = () => import('@/views/Materials/AditionalContent.vue')
const PraticeWriting = () => import('@/views/Materials/PraticeWriting.vue')
const ExamsAssessments = () => import(
  '@/views/Assessments/ExamsAssessments.vue'
)
const ArgumentaAi = () => import('@/views/Materials/ArgumentaAi.vue')
const PAS = () => import('@/views/Materials/PAS.vue')
const PageContent = () => import('@/components/Pages/PageContent.vue')
const RoteirosDeEstudo = () => import('@/views/Materials/Roteiros.vue')

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'Início Documentos' },
  },
  {
    path: '/gestao-escolar/conteudo-programatico',
    name: 'ProgramContent',
    component: ProgramContent,
    meta: { title: 'Conteúdo Programático | Gestão Escolar' },
  },
  {
    path: '/gestao-escolar/marketing/conteudo-e-campanhas-de-mkt',
    name: 'PromotionalMaterial',
    component: PromotionalMaterial,
    meta: { title: 'Materiais de Divulgação | Gestão Escolar' },
  },
  {
    path: '/materiais/material-extra/pratique-redacao',
    name: 'PraticeWriting',
    component: PraticeWriting,
    meta: { title: 'Pratique Redação | Material Didático' },
  },
  {
    path: '/material-didatico/argumenta-ai',
    name: 'ArgumentaAi',
    component: ArgumentaAi,
    meta: { title: 'Argumenta aí | Material Didático' },
  },
  {
    path: '/material-didatico/pas',
    name: 'PAS',
    component: PAS,
    meta: { title: 'PAS | Material Didático' },
  },
  {
    path: '/materiais/material-extra/conteudo-adicional',
    name: 'AditionalContent',
    component: AditionalContent,
    meta: { title: 'Conteúdo Adicional | Material Didático' },
  },
  {
    path: '/avaliacoes/avaliacoes-sas/provas-gabaritos',
    name: 'ExamsAssessments',
    component: ExamsAssessments,
    meta: { title: 'Provas e Gabaritos | Avaliações SAS Educação' },
  },
  {
    path: '/paginas/material-didatico/roteiros-de-estudos',
    name: 'RoteirosDeEstudos',
    component: RoteirosDeEstudo,
    meta: { title: 'Roteiros de Estudo' },
  },
  {
    path: '/paginas/*',
    name: 'PageContent',
    component: PageContent,
    meta: { title: 'Provas e Gabaritos | Avaliações SAS Educação' },
  },
  {
    path: '*',
    component: PageError,
  },
]

export default routes
