const production = {
  AUTH_API: 'https://auth-server.portalsaseducacao.com.br',
  PORTAL_URL: 'https://portalsaseducacao.com.br',
  DOCUMENTS_API: 'https://documents-api.sasdigital.com.br',
  DOWNLOAD_FILES_API: 'https://storage-service.sasdigital.com.br/files',
}

const staging = {
  AUTH_API: 'https://auth-server.staging.portalsaseducacao.com.br',
  PORTAL_URL: 'https://portal.staging.portalsaseducacao.com.br',
  DOCUMENTS_API: 'https://documents-api.staging.sasdigital.com.br',
  DOWNLOAD_FILES_API: 'https://storage-service.staging.sasdigital.com.br/files',
}

const development = {
  ...staging,
  PORTAL_URL: 'http://localhost:8080',
}

const urlsByMode = {
  production,
  staging,
  development,
}

const urls = urlsByMode[process.env.VUE_APP_MODE]

export default urls
