import Vue from 'vue'
import Router from 'vue-router'

import { isAuthenticated, redirectToLogin } from '@/utils/auth'

import routes from './routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({
    x: 0,
    y: 0,
  }),
})

router.beforeEach((to, from, next) => {
  const isUserAuthenticated = isAuthenticated()

  if (isUserAuthenticated) {
    document.title = to.meta.title

    next()
  } else {
    redirectToLogin()
  }
})

const trackView = (to) => {
  const inBrowser = typeof window !== 'undefined'

  if (inBrowser && window.dataLayer) {
    const name = to.meta.gtm || to.name

    window.dataLayer.push({
      event: 'Pageview',
      pagePath: window.location.href,
      pageTitle: name,
    })
  }
}

router.afterEach(trackView)

export default router
