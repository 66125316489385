<template>
  <div class="error">
    <img
      alt=""
      class="error__ilustration"
      src="@/assets/img/404.svg"
    >
    <div class="error__details">
      <h1 class="details__title">
        {{ $t(`pages.error.404.title`) }}
      </h1>
      <p class="details__error-type">
        {{ $t(`pages.error.404.type`) }}
      </p>
      <h4 class="details__comment">
        {{ $t(`pages.error.404.comment`) }}
      </h4>
      <SButton
        :href="portalURL"
        icon-left="arrow-left"
        size="large"
        variation="primary"
      >
        {{ $t(`pages.error.404.backButton`) }}
      </SButton>
    </div>
  </div>
</template>

<script>
import urls from '@/utils/urls'
import { SButton } from '@sas-te/alfabeto-vue'

export default {
  name: 'PageError',
  components: { SButton },
  data() {
    return {
      portalURL: urls.PORTAL_URL,
    }
  },
}
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 144px 72px;

  @include mq_l() {
    flex-direction: row-reverse;
    padding: $size-l $size-xl;
  }

  @include mq_s() {
    display: block;
  }

  &__details {
    @include mq_s() {
      text-align: center;
    }

    .details__title {
      max-width: 648px;
      padding-bottom: $size-s;
      color: $color_main;

      @include mq_l() {
        font-size: $font-size-heading-5;
      }
    }

    .details__error-type {
      padding-bottom: $size-l;
      color: $color-ink-light;

      @include mq_l() {
        padding-bottom: $size-s;
        font-size: $font-size-xs;
      }
    }

    .details__comment {
      max-width: 656px;
      padding-bottom: $size-xl;
      color: $color-ink-light;

      @include mq_l() {
        padding-bottom: $size-l;
        font-size: $font-size-s;
        font-weight: $font-weight-regular;
      }
    }
  }

  &__ilustration {
    @include mq_l() {
      align-self: flex-start;
      width: 50%;
    }

    @include mq_s() {
      width: 100%;
      padding-bottom: $size-s;
    }
  }
}
</style>
