const ptBR = {
  pages: {
    error: {
      404: {
        title: 'Ops! Por essa não esperávamos...',
        type: 'Erro 404 - Página não encontrada',
        comment: 'Acreditamos que você tenha chegado aqui por engano. Podemos levá-lo de volta para que você continue navegando.',
        backButton: 'Voltar para o início',
      },
    },
    school_management: {
      title: 'Gestão Escolar',
      program_content: {
        title: 'Conteúdo Programático',
        description: 'Um resumo do conteúdo abordado pelos livros didáticos do SAS Educação ao longo do ano letivo',
        currentYear: 'Ano Atual',
        nextYear: 'Próximo Ano',
        documentType: 'Tipo de Documento',
        selectType: 'Selecione o tipo',
        grade: 'Série',
        curricular: 'Componente Curricular',
      },
      promotional_material: {
        title: 'Materiais de Divulgação',
        description: 'Acesse peças publicitárias para tornar suas campanhas ainda mais atrativa. Aqui você também encontra modelos de materiais de divulgação para redes sociais.',
        currentYear: 'Ano Atual',
        nextYear: 'Próximo Ano',
        documentType: 'Tipo de Documento',
        selectType: 'Selecione o tipo',
        emptyFilters: 'Selecione todos os filtros.',
      },
    },
    materials: {
      title: 'Material Didático',
      aditional_content: {
        title: ' Conteúdo Adicional',
        module: 'Material Didático',
        description: 'Acesse de forma prática o conteúdo adicional do seu material didático, de acordo com a série e a disciplina',
        grade: 'Série',
        subject: 'Disciplina',
        search: 'Pesquisar',
        emptyFilters: 'Selecione todos os filtros.',
      },
      pratice_writing: {
        title: 'Pratique Redação',
        description: 'Temas diversos e propostas de redação para treinar a escrita dos seus alunos, nos moldes dos maiores vestibulares do país.',
        documentType: 'Tipo de Documento',
        selectType: 'Selecione o tipo',
        grade: 'Série',
        curricular: 'Componente Curricular',
      },
    },
    assessments: {
      title: 'Avaliações SAS Educação',
      examsAssessments: {
        title: ' Provas e Gabaritos',
        module: 'Avaliações SAS Educação',
        description: 'Baixe e imprima os materiais das avaliações e simulados SAS Educação',
        examType: 'Tipo de Exame',
        year: 'Ano',
        edition: 'Edição',
        fase: 'Fase',
        select: 'Escolha',
        moreFilters: 'Mais Filtros',
        examDay: 'Dia de prova',
        subjects: 'Disciplinas',
        emptyFilters: 'Selecione todos os filtros.',
        noResult: 'Não encontramos nada por aqui...',
        empty: 'Use os filtros acima para acessar o documento que você procura',

      },
    },
    argumentaai: {
      title: 'Argumenta aí',
      module: 'Material Didático',
      description: 'Acesse questionamentos sobre temas cotidianos e diferentes posicionamentos, para desenvolver um olhar crítico e uma escrita argumentativa.',
      noResult: 'Não encontramos nada por aqui...',
    },
    pas: {
      title: 'Programa de Avaliação Seriada (PAS)',
      module: 'Material Didático',
      description: 'Acesse de forma prática o material didático específico sobre  o Programa de Avaliação Seriadas (PAS)',
      examType: 'Tipo de Exame',
      emptyFilters: 'Selecione todos os filtros.',
      noResult: 'Não encontramos nada por aqui...',
      empty: 'Use os filtros acima para acessar o documento que você procura',
    },
  },
}

export default ptBR
