import axios from 'axios'

import { deleteCookie, getCookie, setCookie } from './cookies'
import urls from './urls'

const tokenCookieName = 'token'
const refreshTokenCookieName = 'refreshToken'
const sessionCookieName = 'session'

export const getAuthToken = () => getCookie(tokenCookieName)
export const getAuthSession = () => getCookie(sessionCookieName)
export const getRefreshToken = () => getCookie(refreshTokenCookieName)
export const hasRefreshToken = () => !!getRefreshToken()
export const isAuthenticated = () => !!getAuthToken() || hasRefreshToken()

export const redirectToLogin = ({ redirectBack } = { redirectBack: true }) => {
  let params = ''
  const { href } = window.location
  const normalizedURL = href.endsWith('/') ? href.substr(0, href.length - 1) : href

  if (redirectBack && normalizedURL !== urls.PORTAL_URL) {
    const currentLocation = encodeURIComponent(href)

    params += `?redirect=${currentLocation}`
  }

  window.location.assign(`${urls.PORTAL_URL}/${params}`)
}

export const setAuthCookies = ({ token, refreshToken, sessionToken }) => {
  const THIRTY_DAYS_IN_SECONDS = 2592000
  const bearerToken = token.startsWith('Bearer') ? token : `Bearer ${token}`

  setCookie(tokenCookieName, bearerToken)
  setCookie('strategy', 'token')
  setCookie(sessionCookieName, sessionToken)
  setCookie(refreshTokenCookieName, refreshToken, {
    'max-age': THIRTY_DAYS_IN_SECONDS,
  })
}

export const removeAuthCookies = () => {
  deleteCookie(tokenCookieName)
  deleteCookie('strategy')
  deleteCookie(sessionCookieName)
  deleteCookie(refreshTokenCookieName)
}

export const logout = () => {
  removeAuthCookies()
  redirectToLogin({ redirectBack: false })
}

let refreshTokenPromise = null

export const updateTokens = async () => {
  // Prevent refreshToken to be called multiple times
  if (refreshTokenPromise instanceof Promise) {
    await refreshTokenPromise
  } else {
    refreshTokenPromise = axios.post(
      `${urls.AUTH_API}/v2/auth/refreshToken`,
      {
        token: getRefreshToken(),
      },
    )

    const { data } = await refreshTokenPromise

    setAuthCookies(data.authResponse)

    refreshTokenPromise = null
  }
}

export const allowedDomains = ['portalsas.com.br', 'saseducacao.com.br']

if (process.env.VUE_APP_MODE === 'development') {
  allowedDomains.push('localhost')
}

export const isTrustedDomain = (url) => {
  if (typeof url !== 'string') {
    return false
  }

  try {
    return new RegExp(allowedDomains.join('|')).test(new URL(url).hostname)
  } catch {
    return false
  }
}
